import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';
import {
    useLocation,
} from 'react-router-dom';

import {
    PERMISSION,
    ROLE,
} from 'entities/users/constants';
import {
    hasPermission,
} from 'entities/users/functions';
import {
    useUserByMe,
} from 'entities/users/hooks';

import {
    PATH,
} from 'pages/constants';

import NavItem from './NavItem';

import styles from './Navigation.module.scss';

const Navigation: FC<IProps> = (props) => {
    const {authUser} = useUserByMe();

    const location = useLocation();

    return (
        <nav
            className={classNames(styles.navigation, props.className)}
            onClick={props.onClick}
        >
            {
                authUser &&
                <>
                    <NavItem
                        text={'Payments'}
                        isActive={
                            location.pathname.startsWith(PATH.PAYMENT) &&
                            !location.pathname.startsWith(PATH.PAYMENT_METHOD)
                        }
                        isShown={hasPermission(authUser, PERMISSION.PAYMENTS_VIEW)}
                        to={PATH.PAYMENTS}
                    />
                    <NavItem
                        text={'Complaints'}
                        isActive={location.pathname.startsWith(PATH.COMPLAINT)}
                        isShown={hasPermission(authUser, PERMISSION.COMPLAINTS_VIEW)}
                        to={PATH.COMPLAINTS}
                    />
                    <NavItem
                        text={'Accounts'}
                        isActive={location.pathname.startsWith(PATH.ACCOUNT)}
                        isShown={hasPermission(authUser, PERMISSION.ACCOUNTS_VIEW)}
                        to={PATH.ACCOUNTS}
                    />
                    <NavItem
                        text={'Agents'}
                        isActive={location.pathname.startsWith(PATH.AGENT)}
                        isShown={hasPermission(authUser, PERMISSION.AGENTS_VIEW)}
                        to={PATH.AGENTS}
                    />
                    <NavItem
                        text={'Payment methods'}
                        isActive={location.pathname.startsWith(PATH.PAYMENT_METHOD)}
                        isShown={hasPermission(authUser, PERMISSION.PAYMENT_METHODS_VIEW)}
                        to={PATH.PAYMENT_METHODS}
                    />
                    <NavItem
                        text={'Merchants'}
                        isActive={location.pathname.startsWith(PATH.MERCHANT)}
                        isShown={hasPermission(authUser, PERMISSION.MERCHANTS_VIEW)}
                        to={PATH.MERCHANTS}
                    />
                    <NavItem
                        text={'Users'}
                        isActive={location.pathname.startsWith(PATH.USER)}
                        isShown={authUser?.role === ROLE.SUPER_ADMIN}
                        to={PATH.USERS}
                    />
                    <NavItem
                        text={'Admin'}
                        isActive={location.pathname.startsWith(PATH.ADMIN_PANEL)}
                        isShown={authUser?.role === ROLE.SUPER_ADMIN}
                        to={PATH.ADMIN_PANEL}
                    />
                </>
            }
        </nav>
    );
};

export default Navigation;
